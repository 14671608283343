
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class CustomPageTitle extends Vue {

  @Prop()
  public pageTitle?: string;

}
