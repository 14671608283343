<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.links"></custom-page-title>
                <p><a href="https://occsz.e-cegjegyzek.hu/" target="_blank">Online
                    céginformációs szolgáltatás</a></p>

                <p><a href="http://ceginformaciosszolgalat.kormany.hu/"
                      target="_blank">Céginformációs Szolgálat</a></p>

                <p><a href="http://www.kormany.hu/hu/igazsagugyi-miniszterium"
                      target="_blank">Igazságügyi Minisztérium</a></p>

                <p><a href="http://www.magyarorszag.hu/" target="_blank">Kormányzati
                    portál honlapja</a></p>

                <p><a href="http://www.birosag.hu/" target="_blank">Magyarországon
                    működő cégbíróságok</a></p>

                <p><a href="http://www.allamkincstar.gov.hu/" target="_blank">Magyar
                    Államkincstár honlapja</a></p>

                <p><a href="http://www.magyarugyvedikamara.hu/" target="_blank">Magyar
                    Ügyvédi Kamara</a></p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpLinks extends Vue {
}
</script>

<style scoped>

</style>